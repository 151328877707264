<script
    lang="ts"
    setup
>
    type Props = {
        large?: boolean
        medium?: boolean
        small?: boolean
        extraSmall?: boolean
        disabled?: boolean
        active?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        large: false,
        medium: false,
        small: false,
        extraSmall: false,
        disabled: false,
        active: false,
    })

    const style = useCssModule()

    const iconColor = computed<string>(() => (props.active ? '#000' : undefined))

    const buttonIconClass = computed<string[]>(() => {
        const classes = [ style['button-icon'] ]

        if (props.extraSmall) {
            classes.push(style['extra-small'])
        } else if (props.small) {
            classes.push(style['small'])
        } else if (props.large) {
            classes.push(style['large'])
        } else {
            classes.push(style['medium'])
        }

        if (props.active) {
            classes.push(style['active'])
        }

        if (props.disabled) {
            classes.push(style['disabled'])
        }

        return classes
    })
</script>

<template>
    <button
        :class="buttonIconClass"
        :disabled="props.disabled"
        :data-active="props.active || undefined"
    >
        <slot :color="iconColor">
            <LazyAppIconCheckBold :color="iconColor" />
        </slot>
    </button>
</template>

<style
    lang="sass"
    module
    scoped
>
    .button-icon
        appearance: none
        display: flex
        align-items: center
        justify-content: center
        vertical-align: middle
        outline: none
        border: none
        background: none
        transition: background-color var(--transition-default-duration-with-ease)
        will-change: background-color
        cursor: pointer

        &.disabled
            cursor: not-allowed

            &:deep(svg)
                opacity: 0.6

        &:not(.disabled)
            &:hover,
            &.active
                background: #eceef2

        &.extra-small
            width: 24px
            height: 24px
            padding: 4px
            border-radius: 4px

        &.small
            width: 36px
            height: 36px
            padding: 8px
            border-radius: 4px

        &.medium
            width: 48px
            height: 48px
            padding: 12px
            border-radius: 6px

        &.large
            width: 60px
            height: 60px
            padding: 13px
            border-radius: 8px

        &:deep(svg > path)
            transition: fill var(--transition-default-duration-with-ease)
            will-change: fill
</style>
